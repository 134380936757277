@use '../../vars' as *;

.container {
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  background-color: #B1E3FF80;
}

$blur: 30px;

.image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  opacity: 0.8;
  width: calc(100% + 3 * $blur);
  height: calc(100% + 3 * $blur);
  top: -1.5 * $blur;
  left: -1.5 * $blur;
  filter: blur($blur);
}
